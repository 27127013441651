import React, { useEffect, useState } from 'react';
import { Col, Image, Row, Badge, Button, Upload, message, Divider, Input } from 'antd';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { FaThumbsUp } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import PublicLayout from '../../layouts/PublicLayout';
import { DownloadOutlined, LockOutlined, MailOutlined, UploadOutlined } from '@ant-design/icons';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import constants, { PHOTO_STATUS } from '../../../config/constants';
import { getStorage, setStorage } from '../../../helpers';
import { updateOrderDetail } from '../../../redux/order/orderSlice';

import { /* analyzePhoto, */ approveResult, getOrdersById, updateRequest } from '../../../services/orderAPI';

const Result = () => {

  const dispatch = useDispatch();

  const { id } = useParams();
  const [order, setOrder] = useState({});
  const [note, setNote] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const user = useSelector(state => state.auth.user);

  const getOrder = () => {
    getOrdersById(id).then((res) => {
      setOrder({ ...res.data.order });
    }).catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    updateData("fileList", []);
    updateData("promo1", false);
    updateData("promo2", false);
    updateData("note", "");
    updateData("orderId", "");
    getOrder();
  }, []);

  useEffect(() => {
    // console.log(order);
  }, [order]);

  const handleDownload = (url, index) => {
    saveAs(url, `photo_${id}_${index}.jpg`);
  }

  const handleClick = async (file, index) => {
    if (!order.paymentId) {
      // setStorage('prev_url', id);
      const { _id, createdAt, paymentId, refunded, status, updatedAt, userId, __v, ...rest } = order;
      dispatch(updateOrderDetail({
        value: {
          orderId: _id,
          name: userId.name,
          email: userId.email,
          ...rest
        }
      }))
      navigate('/order/checkout');
    } else {
      handleDownload(`${constants.SOCKET_URL}${file.result}`, index);
    }
  }

  const updateData = (field, value) => {
    dispatch(updateOrderDetail({ field, value }));
  }

  const isShipped = (file) => {
    // console.log(file);
    return file.print1 > 0 || file.print2 > 0 || file.print3 > 0 || file.print4 > 0 || file.print5 > 0;
  }

  const packagePrint = () => {
    return order.promo1 || order.promo2;
  }

  return (
    <PublicLayout>
      <div className='p-4'>
        <h1 className="text-center text-2xl">
        </h1>
        <Row gutter={32} justify={'center'} align={"stretch"}>
          {order ? order.fileList?.map((file, index) => <Col key={index} xs={24} sm={12} md={8} lg={6}>
            <div className="flex flex-col justify-between h-full">
              <Badge.Ribbon color="#CB2B8377" text={<div className='py-1'>
                {file.colorize && <p className='m-0 leading-none'><span className="font-bold">&#9679; Colorize</span></p>}
                {(!isShipped(file) && !packagePrint()) && <p className='m-0 leading-none'><span className="font-bold">&#9679; No Print</span></p>}
                {order.promo1 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Print Package(1-8x10 & 2-5x7):</span> Yes</p>}
                {order.promo2 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Wallet Size Print:</span> Yes</p>}
                {file.print1 > 0 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Print 4x6:</span> {file.print1}</p>}
                {file.print2 > 0 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Print 5x7:</span> {file.print2}</p>}
                {file.print3 > 0 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Print 8x10:</span> {file.print3}</p>}
                {file.print4 > 0 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Print 11x14:</span> {file.print4}</p>}
                {file.print5 > 0 && <p className='m-0 leading-none'><span className="font-bold">&#9679; Print 16x20:</span> {file.print5}</p>}
              </div>}><ImgComparisonSlider className='slider-split-line'>
                  <figure slot="first" className="h-full before">
                    <img className='w-full h-full' src={`${constants.SOCKET_URL}${file.path}`} alt="before" />
                    <figcaption>Before</figcaption>
                  </figure>
                  <figure slot="second" className="h-full after">
                    <img className='w-full h-full' src={`${constants.SOCKET_URL}${file.result}`} alt="after" />
                    <figcaption>After</figcaption>
                  </figure>
                  <svg slot="handle" className="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                    <path stroke="#000" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" strokeWidth="1" fill="#fff" vectorEffect="non-scaling-stroke"></path>
                  </svg>
                </ImgComparisonSlider></Badge.Ribbon>
              <div className='text-center mt-4'>
                {(isShipped(file) || packagePrint()) && <>
                  <Button icon={<FaThumbsUp />} block size="large" className='mb-2' disabled={file.approved > 1} type='primary' onClick={() => {
                    approveResult(id, { index }).then(res => {
                      message.success('Successfully approved!');
                      getOrder();
                    }).catch(err => {
                      message.warning("Something went wrong!");
                    })
                  }}>
                    {file.approved > 1 ? PHOTO_STATUS[file.approved] : "Approve for Print"}
                  </Button>
                  <Button type='primary' block size="large" icon={(!order.paymentId) ? <LockOutlined /> : <DownloadOutlined />} onClick={() => handleClick(file, index)}>
                    {(!order.paymentId) ? "Purchase to remove watermark" : "Download High Resolution"}
                  </Button>
                  <Divider>Any Issues?</Divider>
                  <Input.TextArea
                    size='large'
                    placeholder="Please describe what needs to be fixed"
                    autoSize={{
                      maxRows: 5,
                      minRows: 2
                    }}
                    value={note[index]}
                    disabled={file.approved > 1}
                    onChange={e => setNote(prev => ({
                      ...prev,
                      [index]: e.target.value
                    }))}
                  />
                  <Button
                    block
                    size="large"
                    className='my-2'
                    type='primary'
                    disabled={file.approved > 1}
                    onClick={() => {
                      if (!note) return message.warning("Please describe the issues.");
                      updateRequest(id, { index, note: note[index] }).then(res => {
                        message.success('Successfully submited! We will try to restore again.');
                      }).catch(err => {
                        message.warning("Something went wrong!");
                      })
                    }}>
                    Submit
                  </Button>
                </>}
                {(!isShipped(file) && !packagePrint()) && <><Button type='primary' className='mb-2' block size="large" icon={(!order.paymentId) ? <LockOutlined /> : <DownloadOutlined />} onClick={() => handleClick(file, index)}>
                  {(!order.paymentId) ? "Purchase to remove watermark" : "Download High Resolution"}
                </Button>
                  <Divider>Any Issues?</Divider>
                  <Input.TextArea
                    size='large'
                    placeholder="Please describe what needs to be fixed"
                    autoSize={{
                      maxRows: 5,
                      minRows: 2
                    }}
                    value={note[index]}
                    // disabled={file.approved > 1}
                    onChange={e => setNote(prev => ({
                      ...prev,
                      [index]: e.target.value
                    }))}
                  />
                  <Button
                    block
                    size="large"
                    className='my-2'
                    type='primary'
                    // disabled={file.approved > 1}
                    onClick={() => {
                      if (!note) return message.warning("Please describe the issues.");
                      updateRequest(id, { index, note: note[index] }).then(res => {
                        message.success('Successfully submited! We will try to restore again.');
                      }).catch(err => {
                        message.warning("Something went wrong!");
                      })
                    }}>
                    Submit
                  </Button>
                </>}
              </div>
            </div>
          </Col>) : <h1 className='text-2xl'>Order Not Found!</h1>}
          <Col span={24}>
            <p className='text-center mt-2 text-gray-400'>
              Any Issues? <a href="mailto:hello@prophotos.ai">Let us Know.</a>
            </p>
          </Col>
        </Row>
      </div>
    </PublicLayout>
  )
}

export default Result;