import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Divider, Input, Row, Upload, message } from 'antd';
import classNames from 'classnames';
import { PiArrowFatLeftFill, PiArrowFatRightFill } from 'react-icons/pi';
import { RiShieldCheckFill } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import NumberInput from '../../../components/NumberInput';
import PublicLayout from '../../layouts/PublicLayout';
import { CheckCircleFilled, CheckCircleTwoTone } from '@ant-design/icons';
import Header from './Partials/Header';
import dbg from "../../../assets/images/bg.webp";
import pbg from "../../../assets/images/print-bg.jpg";
import constants from '../../../config/constants';
import { updateOrderDetail } from '../../../redux/order/orderSlice';

import { saveOrder } from '../../../services/orderAPI';

function Step3() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDarkMode = useSelector(state => state.app.isDarkMode);
  const orderData = useSelector(state => state.order);

  const [promo1, setPromo1] = useState(orderData.promo1);
  const [promo2, setPromo2] = useState(orderData.promo2);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    updateData('promo1', promo1);
  }, [promo1]);

  useEffect(() => {
    updateData('promo2', promo2);
  }, [promo2]);

  useEffect(() => {
    window.scrollTo(0, 0)
    updateData('step', 2);
  }, []);

  useEffect(() => {
    if (orderData.fileList.length == 0) {
      message.warning('Please upload your photos!');
      navigate('/order/step2');
    }
  }, [orderData]);


  const updateData = (field, value) => {
    dispatch(updateOrderDetail({
      field,
      value,
    }));
  };

  return (
    <PublicLayout>
      {/* <Header /> */}
      <div className="max-w-4xl mx-auto w-full p-8 sm:p-4 text-center">
        <section className='my-16 text-left'>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <div className='flex items-center flex-wrap justify-around'>
                <div className="w-2/3 sm:w-1/3 md:w-1/4 p-2">
                  <img className='w-full rounded-xl' src="/imgs/print.png" alt="" draggable={false} />
                </div>
                <div className='w-full sm:w-2/3 md:w-3/4 flex flex-col px-4 md:px-8 justify-center text-left'>
                  <h1 className='my-2 md:my-2 text-lg sm:text-lg md:text-xl lg:text-2xl font-extrabold'>Would You Like Your Restoration Printed?</h1>
                  <h1 className='my-1 md:my-1 text-sm md:text-[16px] font-bold flex items-center'><RiShieldCheckFill className='mr-2' /> Free Shipping</h1>
                  <h1 className='my-1 md:my-1 text-sm md:text-[16px] font-bold flex items-center'><RiShieldCheckFill className='mr-2' /> Museum Quality Ink</h1>
                  <h1 className='my-1 md:my-1 text-sm md:text-[16px] font-bold flex items-center'><RiShieldCheckFill className='mr-2' /> High Gloss C-Type Silver Halide Photo Paper</h1>
                  <h1 className='my-1 md:my-1 text-sm md:text-[16px] font-bold flex items-center'><RiShieldCheckFill className='mr-2' /> <span className='text-green-600'>100% Moneyback Guarantee</span></h1>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <section className="my-16">
          <Row gutter={[24, 24]} className={classNames('rounded-2xl pt-6', isDarkMode ? 'bg-gray-700' : 'bg-gray-50')}>
            <Col span={24}>
              <h1 className='text-xl md:text-xl lg:text-2xl font-extrabold'>
                Choose Your Sizes <span className='text-gray-400'>(optional)</span>
              </h1>
              <p className="text-sm md:text-[16px] text-gray-400 italic">Select exactly how many you would like for each photo.</p>
            </Col>
            <Col span={24}>
              <div className="overflow-y-auto">
                <table className='w-full'>
                  <thead>
                    <tr className='border-0 border-b border-solid border-b-gray-400'>
                      <th className='w-1/6 p-1 sm:p-2 md:p-4 border-b-1 border-b-solid border-gray-400'></th>
                      <th className='w-1/6 p-1 sm:p-2 md:p-4'>
                        <h3 className='sm:font-bold'>4x6 Print</h3>
                        <small>$10 per photo</small>
                      </th>
                      <th className={classNames('w-1/6 p-1 sm:p-2 md:p-4', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                        <h3 className='sm:font-bold'>5x7 Print</h3>
                        <small>$15 per photo</small>
                      </th>
                      <th className='w-1/6 p-1 sm:p-2 md:p-4'>
                        <h3 className='sm:font-bold'>8x10 Print</h3>
                        <small>$24 per photo</small>
                      </th>
                      <th className={classNames('w-1/6 p-1 sm:p-2 md:p-4', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                        <h3 className='sm:font-bold'>11x14 Print</h3>
                        <small>$34 per photo</small>
                      </th>
                      <th className='w-1/6 p-1 sm:p-2 md:p-4'>
                        <h3 className='sm:font-bold'>16x20 Print</h3>
                        <small>$49 per photo</small>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      orderData.fileList.map((file, index) =>
                        <tr key={index}>
                          <td className='p-1 sm:p-2 md:p-4'>
                            {file.path ? <img src={`${constants.SOCKET_URL}${file.path}`} className='w-14 h-14 rounded object-cover' alt="" /> : <div className='w-14 h-14 bg-gray-200 rounded shadow flex items-center justify-center'>
                              <h1 className="font-bold text-purple-600 m-0">{index + 1}</h1>
                            </div>}
                          </td>
                          <td className='p-1 sm:p-2 md:p-4'>
                            <NumberInput isDarkMode={isDarkMode} value={file.print1} onChange={(value) => {
                              const files = orderData.fileList.map(file => ({ ...file }));
                              files[index].print1 = value;
                              updateData('fileList', files);
                            }} />
                          </td>
                          <td className={classNames('p-1 sm:p-2 md:p-4', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                            <NumberInput isDarkMode={isDarkMode} value={file.print2} onChange={(value) => {
                              const files = orderData.fileList.map(file => ({ ...file }));
                              files[index].print2 = value;
                              updateData('fileList', files);
                            }} />
                          </td>
                          <td className='p-1 sm:p-2 md:p-4'>
                            <NumberInput isDarkMode={isDarkMode} value={file.print3} onChange={(value) => {
                              const files = orderData.fileList.map(file => ({ ...file }));
                              files[index].print3 = value;
                              updateData('fileList', files);
                            }} />
                          </td>
                          <td className={classNames('p-1 sm:p-2 md:p-4', isDarkMode ? 'bg-gray-800' : 'bg-gray-100')}>
                            <NumberInput isDarkMode={isDarkMode} value={file.print4} onChange={(value) => {
                              const files = orderData.fileList.map(file => ({ ...file }));
                              files[index].print4 = value;
                              updateData('fileList', files);
                            }} />
                          </td>
                          <td className='p-1 sm:p-2 md:p-4'>
                            <NumberInput isDarkMode={isDarkMode} value={file.print5} onChange={(value) => {
                              const files = orderData.fileList.map(file => ({ ...file }));
                              files[index].print5 = value;
                              updateData('fileList', files);
                            }} />
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </section>

        {/* <section className="my-16 text-white">
          <Row gutter={[24, 24]} className='bg-cover rounded-2xl py-6 relative' align={"center"} style={{
            backgroundImage: `url('${dbg}')`
          }}>
            <div className="absolute top-0 left-0 w-full h-full bg-[#000a] rounded-2xl"></div>
            <Col md={20} xs={24}>
              <h1 className='text-xl md:text-3xl font-bold'>
                Want to add frames for your prints?
              </h1>
              <p className="text-md md:text-xl font-gray-400 mb-0">You can browse our selection of frames and find the perfect one to complete your prints after you approve the digital finished versions of your photos. Continue below for now!</p>
            </Col>
          </Row>
        </section> */}

        <section className='mt-4 mb-8'>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <div className="flex items-center justify-center flex-wrap">
                <Button className='w-full sm:w-44 m-2 inline-flex items-center justify-center' size='large' onClick={() => {
                  updateData('step', 1);
                  navigate('/order/step2');
                }}><PiArrowFatLeftFill /> &nbsp; Back</Button>
                <Button onClick={() => {
                  setLoading(true);
                  saveOrder({ orderData }).then(res => {
                    // console.log(res);
                    updateData("orderId", res.data.orderId);
                    navigate('/order/checkout');
                  }).catch(err => {
                    console.log(err);
                  }).finally(() => {
                    setLoading(false);
                  });
                }} className='w-full sm:w-96 m-2 inline-flex items-center justify-center' size='large' type='primary' loading={loading}>Click to go to Checkout &nbsp; <PiArrowFatRightFill /></Button>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </PublicLayout>
  )
}

export default Step3;