import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  step: 0,
  name: "",
  email: "",
  option: "ready",
  artist: 'mid',
  fileList: [],
  promo1: false,
  promo2: false,
  processingDelay: 0,
  shippingDelay: 0,
  address: {},
  note: "",
  orderId: "",
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateOrderDetail(state, action) {
      if (action.payload.field) {
        state[action.payload.field] = action.payload.value;
      } else {
        for (const key in action.payload.value) {
          if (Object.hasOwnProperty.call(action.payload.value, key)) {
            state[key] = action.payload.value[key];
          }
        }
      }
    },
  },
});

export const { updateOrderDetail } = orderSlice.actions;
export default orderSlice.reducer;
