import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Divider, Form, Input, Row, message } from 'antd';
import classNames from 'classnames';
import { PiArrowFatRightFill } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import PublicLayout from '../../layouts/PublicLayout';
import { CheckCircleFilled, CheckCircleTwoTone } from '@ant-design/icons';
import Header from './Partials/Header';
import { updateOrderDetail } from '../../../redux/order/orderSlice';

import { saveUser } from '../../../services/authAPI';
import { checkPromo } from '../../../services/planAPI';

function Step1() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isDarkMode = useSelector(state => state.app.isDarkMode);

  const orderData = useSelector(state => state.order);

  const [form] = Form.useForm();
  const [option, setOption] = useState(orderData.option);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkPromo(location.search).then(({ data }) => {
      updateData('coupon', data.coupon);
    }).catch(err => console.log(err));
    window.scrollTo(0, 0);
    updateData('step', 0);
  }, []);

  useEffect(() => {
    updateData('option', option);
    updateData('fileList', []);
  }, [option]);


  const updateData = (field, value) => {
    dispatch(updateOrderDetail({ field, value }));
  }

  const onFinish = (values) => {
    setLoading(true);
    saveUser(values, location.search).then(({ data }) => {
      updateData('step', 1);
      updateData('coupon', data.coupon);
      navigate('/order/step2');
    }).catch(err => {
      message.error("Something went wrong!");
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <PublicLayout>
      {/* <Header /> */}
      <div className="max-w-4xl mx-auto w-full p-4 text-center">
        <Form
          name="register-user"
          form={form}
          className="form"
          scrollToFirstError
          initialValues={{
            name: orderData.name,
            email: orderData.email,
          }}
          onFinish={onFinish}
        >
          <section className='my-8 sm:my-16 text-left'>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <h1 className='text-lg md:text-xl lg:text-2xl'>First & Last Name</h1>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your First & Last Name!',
                    },
                  ]}
                >
                  <Input size='large' placeholder='First & last name' value={orderData.name} onChange={(e) => updateData('name', e.target.value)} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <h1 className='text-lg md:text-xl lg:text-2xl'>Email</h1>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input size="large" placeholder='Your Best Email Address' value={orderData.email} onChange={(e) => updateData('email', e.target.value)} />
                </Form.Item>
              </Col>
            </Row>
          </section>

          <Divider className='my-8 sm:my-16' />
          <section className='mt-4 mb-8'>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Button htmlType='submit' className='w-96 inline-flex items-center justify-center' size='large' type='primary' loading={loading}>Click to go to Step 2 &nbsp; <PiArrowFatRightFill /></Button>
              </Col>
            </Row>
          </section>
        </Form>
      </div>
    </PublicLayout>
  )
}

export default Step1;